import React, { useContext, useEffect, useState, useRef } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { RemoveScroll } from "react-remove-scroll";
import { useClickAway } from "react-use";

// Previews
import { withPreview } from "gatsby-source-prismic";

// Context
import { PageBackgroundColor } from "../components/context/page-background-color";

// Components
import { AspectRatioImageContainer } from "../components/containers/aspect-ratio-image-container";
import { PageSEO } from "../components/seo/page-seo";

// Hooks
import { useMediaQuery } from "../components/hooks/useMediaQuery";

// Utils
import { ImageOrientation } from "../components/utils/image-orientation";

const Page = styled.div`
  margin: 0 0 170px 0;

  @media (max-width: 768px) {
    margin: 0 0 55px 0;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 40px;

  @media (max-width: 1350px) {
    grid-column-gap: 20px;
  }

  & .content-grid {
    grid-column: 1 / 12;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 160px;
    grid-row-gap: 60px;
  }

  @media (max-width: 768px) {
    display: block;
    grid-template-columns: unset;
    grid-column-gap: unset;

    & .content-grid {
      grid-column: unset;

      display: unset;
      grid-template-columns: unset;
      grid-column-gap: unset;
      grid-row-gap: unset;
    }
  }
`;

const Exhibition = styled.div`
  & .exhibition-text {
    margin: 10px 0 0 0;

    @media (max-width: 768px) {
      margin: 2px 0 0 0;
    }

    & .exhibition-type {
      font-size: 14px;
      line-height: 26px;

      text-transform: capitalize;
    }

    & .title,
    & .subtitle,
    & .details {
      & h1,
      & p {
        font-size: 18px;
        line-height: 26px;

        @media (max-width: 768px) {
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.05em;
        }
      }
    }

    & h1,
    & p {
      text-align: center;
      letter-spacing: 0.05em;
    }

    & h1 {
      & em {
        font-family: "Nantes", "Helvetica Neue", "Lucida Grande", sans-serif;
        font-style: italic;
      }
    }

    & p {
      margin: 0;
    }

    & .exhibition-details {
    }
  }

  & img {
    z-index: 0;
    object-fit: cover;
    height: 100%;
  }

  &:hover {
    & .fill {
      opacity: 1;
    }
  }

  & .inner-overview-image-container {
    position: relative;
    height: 100%;
    width: fit-content;
    margin: 0 auto;
  }

  @media (max-width: 768px) {
    margin: 0 0 50px 0;

    &.portrait {
      max-width: 80%;
    }
  }
`;

const DesktopFilters = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 40px;

  @media (max-width: 1350px) {
    grid-column-gap: 20px;
  }

  @media (max-width: 1100px) {
    display: flex;
    grid-template-columns: unset;
    grid-column-gap: unset;
  }

  grid-column: 1 / 13;

  margin: 25px 0 20px 0;

  & button {
    margin: 0 15px 0 0;
    text-transform: capitalize;
    text-align: left;

    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.05em;

    &:last-of-type {
      margin: 0;
    }

    & span {
      opacity: 0;
      transition: 250ms opacity ease;
    }

    &:hover,
    &.active-tag {
      & span {
        opacity: 1;
      }
    }

    & svg {
      margin: 0 7px 0 0;
    }

    &.view-all {
      @media (max-width: 1100px) {
        margin: 0 15px 0 0;
      }
    }
  }

  & .tags-container {
    grid-column: 2 / 12;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

const MobileFilters = styled.div`
  margin: 0 0 20px 0;
  grid-column: 1 / 13;

  & .mobile-filters-container {
    & button {
      display: block;

      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.05em;

      margin: 0 0 10px 0;
      text-transform: capitalize;
      text-align: left;

      color: #163262;

      & span {
        opacity: 0;
        transition: 250ms opacity ease;
      }

      &:hover,
      &.active-tag {
        & span {
          opacity: 1;
        }
      }

      & svg {
        margin: 0 7px 0 0;
      }
    }

    & .tags-container {
    }
  }

  &.visible {
    & .mobile-filters-container {
      position: absolute;

      top: 0;
      left: 0;
      right: 0;

      background-color: #5694fd;

      z-index: 10;

      padding: 124px 40px 20px 40px;

      @media (max-width: 768px) {
        padding: 107px 20px 20px 20px;
      }

      & button {
        color: #163262;

        &.toggle {
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.06em;
        }
      }
    }
  }

  & .toggle {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.06em;
  }

  @media (max-width: 768px) {
    & .mobile-filters-container {
      & button {
        & span {
          display: none;
        }

        &.active-tag {
          & span {
            display: inline-block;
          }
        }
      }
    }
  }
`;

const Fill = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: #4383ef;
  mix-blend-mode: multiply;
  transition: 250ms opacity ease;

  opacity: 0;
  z-index: 1;
`;

const Exhibitions = ({ data, location }) => {
  const [pageBackgroundColor, setPageBackgroundColor] = useContext(
    PageBackgroundColor
  );

  // Mobile Filters
  const [isMobileFiltersVisible, setMobileFiltersVisible] = useState(false);
  const mobileFiltersRef = useRef(null);

  useClickAway(mobileFiltersRef, () => {
    setMobileFiltersVisible(false);
  });

  // Tags and filters
  const [activeTag, setActiveTag] = useState(`all`);

  // Media Query
  let isDesktop = useMediaQuery("(min-width: 1075px)");

  useEffect(() => {
    setPageBackgroundColor({
      text: `#4383EF`,
      background: `#ffffff`,
    });
  }, []);

  const scrollToElement = element => {
    var rect = document.getElementById(element).getBoundingClientRect();
    var offsetTop = rect.top - 100;
    window.scrollTo(0, offsetTop);
  };

  useEffect(() => {
    setMobileFiltersVisible(false);
  }, [activeTag]);

  useEffect(() => {
    if (isMobileFiltersVisible === true) {
      document.body.classList.add(`mobile-filters-open`);
    } else {
      document.body.classList.remove(`mobile-filters-open`);
    }
  }, [isMobileFiltersVisible]);

  useEffect(() => {
    if (
      location !== null &&
      location !== undefined &&
      location !== `undefined`
    ) {
      if (
        location.state !== null &&
        location.state !== undefined &&
        location.state !== `undefined`
      ) {
        if (location.state.activeTag !== undefined) {
          setActiveTag(location.state.activeTag);

          if (location.state.exhibitionId !== undefined) {
            const scrollWithTimeout = setTimeout(() => {
              scrollToElement(location.state.exhibitionId);
            }, 50);
            return () => clearTimeout(scrollWithTimeout);
          }
        }
      }
    }
  }, [location]);

  const tags = Array.from(
    new Set(
      data.allPrismicExhibition.edges
        .filter(exhibition => exhibition.node !== null)
        .map(exhibition => exhibition.node.data.exhibition_type)
        .flat()
    )
  )
    .sort((a, b) => b - a)
    .map((tag, index) => (
      <button
        key={`single_exhibition_tag_${index}`}
        onClick={() => setActiveTag(tag)}
        className={activeTag === tag ? `active-tag` : ``}
      >
        <span>(</span>
        {tag}s<span>)</span>
      </button>
    ));

  const content = data.allPrismicExhibition.edges
    .filter(exhibition => exhibition.node !== null)
    .filter(exhibition => {
      if (activeTag === `all`) {
        return exhibition;
      } else {
        if (activeTag === exhibition.node.data.exhibition_type) {
          return exhibition;
        }
      }
    })
    .map((exhibition, index) => (
      <Exhibition key={`single_exhibition_${index}`} id={exhibition.node.id}>
        <Link to={exhibition.node.url} state={{ activeTag: activeTag }}>
          {exhibition.node.data.images.length >= 1 && (
            <>
              {exhibition.node.data.images[0].image.fluid !== null && (
                <AspectRatioImageContainer image={null} padding={66}>
                  <img
                    className={ImageOrientation(
                      exhibition.node.data.images[0].image
                    )}
                    srcSet={
                      exhibition.node.data.images[0].image.fluid.srcSetWebp
                    }
                    src={exhibition.node.data.images[0].image.fluid.srcWebp}
                    alt={exhibition.node.data.images[0].image.alt}
                    loading={index <= 3 ? `eager` : `lazy`}
                  />
                  <Fill className="fill" />
                </AspectRatioImageContainer>
              )}
            </>
          )}
        </Link>

        <div className="exhibition-text">
          <p className="exhibition-type">
            {exhibition.node.data.exhibition_type}
          </p>

          <div
            className="title"
            dangerouslySetInnerHTML={{
              __html: exhibition.node.data.title.html,
            }}
          />

          <div className="exhibition-details">
            <div
              className="subtitle"
              dangerouslySetInnerHTML={{
                __html: exhibition.node.data.subtitle.html,
              }}
            />

            <div
              className="details"
              dangerouslySetInnerHTML={{
                __html: exhibition.node.data.details.html,
              }}
            />
          </div>
        </div>
      </Exhibition>
    ));

  return (
    <>
      <PageSEO
        title={data.prismicExhibitions.data.title.text}
        description={null}
        image={null}
        url={`https://yinkashonibare.com${data.prismicExhibitions.url}`}
      />
      <Page>
        {isDesktop ? (
          <DesktopFilters>
            <button
              onClick={() => setActiveTag(`all`)}
              className={
                activeTag === `all` ? `active-tag view-all` : ` view-all`
              }
            >
              <span>(</span>View All<span>)</span>
            </button>

            <div className="tags-container">
              <div className="tags">{tags}</div>
            </div>
          </DesktopFilters>
        ) : (
          <MobileFilters
            className={isMobileFiltersVisible ? `visible` : `hidden`}
            ref={mobileFiltersRef}
          >
            <button
              className="toggle open"
              onClick={() => {
                setMobileFiltersVisible(!isMobileFiltersVisible);
              }}
            >
              Filter {isMobileFiltersVisible ? `-` : `+`}
            </button>

            {isMobileFiltersVisible && (
              <RemoveScroll>
                <div className="mobile-filters-container">
                  <button
                    className="toggle close"
                    onClick={() => {
                      setMobileFiltersVisible(false);
                    }}
                  >
                    Filter -
                  </button>

                  <button
                    onClick={() => setActiveTag(`all`)}
                    className={
                      activeTag === `all` ? `active-tag view-all` : ` view-all`
                    }
                  >
                    <span>(</span>View All<span>)</span>
                  </button>

                  <div className="tags-container">
                    <div className="tags">{tags}</div>
                  </div>
                </div>
              </RemoveScroll>
            )}
          </MobileFilters>
        )}

        <Grid>
          <div className="content-grid">{content}</div>
        </Grid>
      </Page>
    </>
  );
};

export default withPreview(Exhibitions);

export const query = graphql`
  {
    prismicExhibitions {
      url
      data {
        title {
          text
        }
      }
    }
    allPrismicExhibition(
      sort: { fields: data___publishing_date, order: DESC }
    ) {
      edges {
        node {
          url
          id
          data {
            images {
              image {
                alt
                fluid {
                  srcWebp
                  srcSetWebp
                  aspectRatio
                }
                dimensions {
                  height
                  width
                }
              }
            }
            title {
              html
            }
            subtitle {
              html
            }
            exhibition_type
            details {
              html
            }
          }
        }
      }
    }
  }
`;
